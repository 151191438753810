import React from "react";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import { LABELS } from "../../common/constant/const";
const series = [
  { category: "Panchakajaya", value: 30, color: "#754355" },
  { category: "Kunkumarchane", value: 30, color: "#437275" },
  { category: "Karthikapuje", value: 10, color: "#43755D" },
  { category: "Ranga Pooje", value: 30, color: "#93204A" },
];

const labelContent = (props) => `${props.dataItem.category}`;

const DailySevaRevenue = () => {
  return (
    <Chart>
      <ChartArea background="#feedd5"/>
      <ChartTitle text={LABELS.DAILY_POOJA_SEVA_REVENUE} />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem
          type="pie"
          data={series}
          field="value"
          categoryField="category"
          colorField="color"
          labels={{
            visible: true,
            content: labelContent,
          }}
        />
      </ChartSeries>
    </Chart>
  );
};

export default DailySevaRevenue;
