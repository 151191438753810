import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { menuIcon } from "@progress/kendo-svg-icons";
import { NavItems } from "../../../common/nav-items";
import launcher_icon from "../../../images/launcher_icon.png";
import { ModeToggle } from "../../../ui/mode-toggle";

import "./styles.css";

const DrawerRouterContainer = (props) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);
  const [selected, setSelected] = React.useState(
    NavItems.findIndex((x) => x.selected === true)
  );

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const onSelect = (e) => {
    navigate(e.itemTarget.props.route);
    setSelected(e.itemIndex);
  };

  return (
    <div className="h-screen flex flex-col bg-[#fddeb2] dark:bg-[#000000]">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-[#C49871] dark:bg-[#3b3b3b] shadow flex items-center justify-between pl-[5px]">
        <div className="flex items-center space-x-3">
          <Button svgIcon={menuIcon} fillMode="flat" onClick={handleClick} />
          <img src={launcher_icon} alt="Logo" className="w-8 h-8 ml-1" />
          <span className="text-xl font-semibold">MY DHAARMIK</span>
        </div>
        <div>
          <ModeToggle />
        </div>
      </header>

      <div className="flex flex-1 pt-16">
        <Drawer
          expanded={expanded}
          position={"start"}
          mode={"push"}
          mini={true}
          items={NavItems.map((item, index) => ({
            ...item,
            selected: index === selected,
          }))}
          onSelect={onSelect}
          className={`w-full ${
            expanded ? "drawer-expanded" : "drawer-collapsed"
          }`}
        >
          <DrawerContent className="w-full">
            <div className="flex justify-center items-center w-full">
              {props.children}
            </div>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  );
};

export default DrawerRouterContainer;
