import {
  cellSplitHorizontallyIcon,
  layout2By2Icon,
  listUnorderedSquareIcon,
  dataIcon,
  layoutIcon,
  logoutIcon,
} from "@progress/kendo-svg-icons";
import { LABELS } from "./constant/const";

const navItemClassName =
  "hover:bg-[#67301142] dark:hover:bg-[#67301142] p-3 transition-colors duration-200";
// const logOut = "p-3 transition-colors duration-200  rounded-full  hover:bg-[#67301142] dark:hover:bg-[#67301142] mt-[600px] text-black dark:text-white";

export const NavItems = [
  {
    id: "dashboard",
    text: LABELS.DASHBOARD,
    svgIcon: layout2By2Icon,
    route: "/dashboard",
    className: "hidden md:flex lg:flex " + navItemClassName,
    selected: true,
  },
  {
    id: "sevalist",
    text: LABELS.SEVALIST,
    svgIcon: listUnorderedSquareIcon,
    route: "/seva-list",
    className: "hidden md:flex lg:flex " + navItemClassName,
  },
  {
    id: "sevamanagement",
    text: LABELS.SEVA_MANAGEMENT,
    svgIcon: dataIcon,
    route: "/seva-management",
    className: navItemClassName,
  },
  {
    id: "events",
    text: LABELS.EVENTS,
    svgIcon: layoutIcon,
    route: "/events",
    className: navItemClassName,
  },
  {
    id: "abouttemple",
    text: LABELS.ABOUT_TEMPLE,
    svgIcon: cellSplitHorizontallyIcon,
    route: "/about-temple",
    className: navItemClassName,
  },
  {
    id: "logout",
    text: LABELS.LOGOUT,
    svgIcon: logoutIcon,
    route: "/logout",
    className: navItemClassName,
  },
];
