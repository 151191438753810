import { Dialog } from "@progress/kendo-react-dialogs";

import React from "react";

import { BUTTONS } from "../../common/constant/const";
import { Button } from "../../ui/button";

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
  return (
    <Dialog
      title={<span className="font-bold">Confirm</span>}
      onClose={onCancel}
      width={450}
    >
      <div>{message}</div>
      <div className="mt-5 flex justify-between">
        <Button
          onClick={onCancel}
          className="bg-[#673011] text-[#FFFFFF] mb-10  rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
        >
          {BUTTONS.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          className="bg-[#673011] text-[#FFFFFF] mb-10  rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
        >
          {BUTTONS.CONFIRM}
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
