import React, { useEffect, useState } from "react";
import { NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Check } from "lucide-react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import upload from "../../common/constant/upload";
import { BUTTONS, SUB_TITLES } from "../../common/constant/const";
import "../seva-list/style.css";

const AddSevaModal = ({ sevaData, addSeva, editSeva, onClose }) => {
  const [formData, setFormData] = useState(sevaData || {});
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 412);
  const [imagePreview, setImagePreview] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    setFormData(sevaData || {});
    setImagePreview(sevaData?.seva_photo || "");
  }, [sevaData]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 412);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.id) {
      editSeva(formData);
      setSuccessMessage("Seva updated successfully!");
    } else {
      addSeva(formData);
      setSuccessMessage("Seva added successfully!");
    }
    setTimeout(() => {
      setSuccessMessage("");
      onClose();
    }, 2000);
  };

  const findDropDownValue = (value) => {
    return [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ].find((item) => item.value === value);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    document.getElementById("fileInput").click();
  };

  const handleSevaPhoto = async (event) => {
    setIsImageLoading(true);
    const bucketName = "mydharmik-seva";
    const fileObj = event.target.files[0];
    const uploadedImageUrl = await upload(fileObj, bucketName);
    if (uploadedImageUrl) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        seva_photo: uploadedImageUrl
          ? uploadedImageUrl
          : "gs://mydharmik-seva/default_seva.jpeg",
      }));
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
    setIsImageLoading(false);
  };

  return (
    <Dialog
      title={
        <span className="font-bold">
          {formData.id ? "Update Seva" : "Add Seva"}
        </span>
      }
      onClose={onClose}
      className={isSmallScreen ? "custom-dialog-xs" : ""}
    >
      {successMessage && (
        <div className="flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
          <div className="w-10">
            <Check />
          </div>
          {successMessage}
        </div>
      )}
      <style>
        {isSmallScreen &&
          `
      .custom-dialog-xs .k-dialog {
        width: 100% !important;
      }
      .custom-dialog-xs .custom-button-xs {
        width: 300px !important;
      }
    `}
      </style>
      <form onSubmit={handleSubmit} className="space-y-5 p-5 md:p-1">
        <div>
          <Label className="block mb-2">{SUB_TITLES.SEVA_NAME}</Label>
          <Input
            name="seva_name"
            value={formData.seva_name || ""}
            onChange={handleChange}
            required={true}
            className="w-full bg-[#feedd5]  dark:text-[#FFFFFF] dark:bg-[#3b3b3b] rounded-[7px]"
          />
        </div>
        <div>
          <Label className="block mb-2">{SUB_TITLES.SEVA_PHOTO}</Label>
          {imagePreview ? (
            <div className="flex flex-col items-start">
              <img
                src={imagePreview}
                alt="Seva Preview"
                className="w-[100px] h-[100px] mb-2"
              />
              <Button
                look="outline"
                className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000]
               ${isSmallScreen ? "custom-button-xs" : ""}`}
                onClick={handleButtonClick}
              >
                Change Seva Photo
              </Button>
            </div>
          ) : (
            <Button
              look="outline"
              className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000] ${
                isSmallScreen ? "custom-button-xs" : ""
              }`}
              onClick={handleButtonClick}
            >
              Upload Seva Image
            </Button>
          )}
          <input
            id="fileInput"
            type="file"
            onChange={handleSevaPhoto}
            className="hidden"
          />
        </div>
        <div>
          <Label className="block mb-2">{SUB_TITLES.SEVA_AVAILABILITY}</Label>
          <DropDownList
            data={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
            textField="text"
            dataItemKey="value"
            value={findDropDownValue(formData.seva_availability)}
            onChange={(event) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                seva_availability: event.target.value.value,
              }))
            }
            className="w-full dark:text-[#FFFFFF] dark:bg-[#3b3b3b] border-[#673011] p-[2px] bg-[#feedd5] dark:border-[#ffffff]"
          />
        </div> 
        <div>
          <Label className="block mb-2">{SUB_TITLES.SEVA_AMOUNT}</Label>
          <NumericTextBox
            name="seva_price"
            type="number"
            value={formData.seva_price}
            onChange={handleChange}
            required={true}
            min={0}
            className="w-full bg-[#feedd5] dark:text-[#FFFFFF] dark:bg-[#3b3b3b] mt-1"
          />
        </div>
        <div>
          <Label className="block mb-2">{SUB_TITLES.SEVA_COMMENT}</Label>
          <TextArea
            name="seva_description"
            multiline={true}
            rows={4}
            value={formData.seva_description || ""}
            onChange={handleChange}
            className="w-full bg-[#feedd5] dark:text-[#FFFFFF] dark:bg-[#3b3b3b] mt-1"
          />
        </div>
        <div className="flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-2 mt-5">
          <Button
            onClick={onClose}
            type="button"
            className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:bg-[#FFFFFF] dark:text-[#000000] dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
            {BUTTONS.CLOSE}
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isImageLoading}
            className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:bg-[#FFFFFF] dark:text-[#000000] dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
            {formData.id ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddSevaModal;
