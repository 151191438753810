import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from '../../components/dashboard';
import Login from '../../components/login';
import Events from '../../components/seva-events';
import SevaList from '../../components/seva-list';
import SevaManagement from '../../components/seva-management';
import Logout from '../../components/logout/logout';
import AboutTemple from '../../components/about-temple';
import DrawerRouterContainer from "../../components/layouts/left-drawer/drawer-router-container"

const RoutesComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="app-container">
      <div className="content">
        {isLoggedIn ? (
          <DrawerRouterContainer>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/seva-list" element={<SevaList />} />
              <Route path="/seva-management" element={<SevaManagement />} />
              <Route path="/events" element={<Events />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/about-temple" element={<AboutTemple />} />
            </Routes>
          </DrawerRouterContainer>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        )}
      </div>
    </div>
  );
};

export default RoutesComponent;
