"use client"

import * as React from "react"
import { MoonIcon, SunIcon } from "@radix-ui/react-icons"
import { useTheme } from "next-themes"
import { Button } from "../ui/button"

export function ModeToggle() {
  const { setTheme, resolvedTheme } = useTheme()

  return (
    <Button
      variant="outline"
      size="icon"
      aria-label="Toggle theme"
      onClick={() => setTheme(resolvedTheme === "dark" ? "light" : "dark")}
    >
      <SunIcon
        className={`h-[1.2rem] w-[1.2rem] transition-transform ${
          resolvedTheme === "dark"
            ? "rotate-90 scale-0"
            : "rotate-0 scale-100"
        }`}
      />
      <MoonIcon
        className={`absolute h-[1.2rem] w-[1.2rem] transition-transform ${
          resolvedTheme === "dark"
            ? "rotate-0 scale-100"
            : "rotate-90 scale-0"
        }`}
      />
    </Button>
  )
}