import { Button } from "../../../ui/button";
import { Minus, Plus,  Check } from "lucide-react";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "../../../ui/dropdown-menu";

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../../common/constant/axiosInstance";
import templeID from "../../../common/constant/temple-id";
import { BUTTONS, SUB_TITLES } from "../../../common/constant/const";
import upload from "../../../common/constant/upload";
import "../style.css";

const AddSevaModal = ({ onClose, onRefresh, addPrint }) => {
  const [sevaOptions, setSevaOptions] = useState([]);
  const [templeId, setTempleId] = useState(null);
  const [rows, setRows] = useState([{ seva_name: "", seva_units: 0 }]);
  const [devotees, setDevotees] = useState([
    {
      devotee_id: 1,
      devotee_name: "",
      devotee_gotra: "Select Gothra",
      devotee_rashi: " Select Rashi",
      devotee_nakshatra: "Select Nakshathra",
      devotee_relationship: "Select Relationship",
    },
  ]);
  const [lastId, setLastId] = useState(1);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState({
    total_amount: 0,
    payment_type: "cash",
    payment_status: "pending",
    payment_provider: "",
    pay_at_temple: "pending",
    transaction_id: "",
    transaction_ref_id: "",
    seva_date: new Date(),
    seva_time: new Date(),
    seva_reason: "",
    seva_status: "on-processing",
    user_seva_completed_image: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [setImagePreview] = useState("");
  const [formData, setFormData] = useState({
    user_seva_completed_image: "",
  });

  useEffect(() => {
    const fetchTempleId = async () => {
      try {
        const fetchedTempleId = await templeID();
        setTempleId(fetchedTempleId);
        console.log("Fetched Temple ID:", fetchedTempleId);
      } catch (error) {
        console.error("Error fetching temple ID:", error);
      }
    };
    fetchTempleId();
  }, []);

  useEffect(() => {
    const fetchSevaOptions = async () => {
      try {
        if (!templeId) return;
        const response = await axiosInstance.get(
          `/api/v1/temple-seva/${templeId}`
        );
        const sevas = response.data.data;
        setSevaOptions(sevas);
      } catch (error) {
        console.error("Error fetching seva data: ", error);
      }
    };

    fetchSevaOptions();
  }, [templeId]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (time) => {
    return {
      hours: time.getHours(),
      minutes: time.getMinutes(),
      seconds: time.getSeconds(),
    };
  };

  const handleSubmit = async () => {
    const transaction_id = uuidv4();
    let imageUrl = formData.user_seva_completed_image;

    console.log("Initial formData.user_seva_completed_image:", formData.user_seva_completed_image);
    console.log("Selected file:", selectedFile);

    if (selectedFile) {
      const bucketName = "mydharmik-sevalist";
      try {
        imageUrl = await upload(selectedFile, bucketName);
        console.log("Uploaded image URL:", imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      console.log("No selected file. Using formData.user_seva_completed_image:", imageUrl);
    }

    const formattedPaymentDetails = {
      ...paymentDetails,
      seva_date: formatDate(paymentDetails.seva_date),
      seva_time: formatTime(paymentDetails.seva_time),
      pay_at_temple:
        paymentDetails.pay_at_temple === "pending" ? "false" : "true",
      payment_provider:
        paymentDetails.payment_type === "cash" ? "cash" : "online",
      transaction_id: transaction_id,
      transaction_ref_id: transaction_id,
      user_seva_completed_image: imageUrl,
    };
    const data = {
      seva_items_model: rows,
      seva_payment_model: formattedPaymentDetails,
      devotee_details: devotees,
      transaction_date_time: new Date().toISOString(),
    };

    console.log("Data to be submitted:", data);
    const userId = localStorage.getItem("userId");

    try {
      const response = await axiosInstance.post(
        `/api/v1/seva-offering/${userId}`,
        data
      );
      if (response.data.status) {
        console.log("Temple seva created successfully", response);
        setReceiptNumber(response.data.data.seva_payment_model.receipt_number);
        setIsSubmitted(false);
        setSuccessMessage("Data updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      } else {
        console.error("Failed to create temple seva", response.data);
      }
    } catch (error) {
      console.error("Error posting data: ", error);
    }
  };

  const handlePrint = async () => {
    await addPrint(receiptNumber);
    onClose();
    onRefresh();
  };

  const handleClose = async () => {
    onClose();
    onRefresh();
  };

  const handleIncrement = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        seva_units: updatedRows[index].seva_units + 1,
      };
      updatedRows[index].seva_total =
        updatedRows[index].seva_price * updatedRows[index].seva_units;
      return [...updatedRows];
    });
  };

  const handleDecrement = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        seva_units: Math.max(updatedRows[index].seva_units - 1, 0),
      };
      updatedRows[index].seva_total =
        updatedRows[index].seva_price * updatedRows[index].seva_units;
      return [...updatedRows];
    });
  };

  const handleDelete = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  const handleAddSeva = () => {
    setRows((prevRows) => [...prevRows, { seva_name: "", seva_units: 1 }]);
  };

  const getSevaPrice = (sevaName) => {
    const seva = sevaOptions.find((option) => option.seva_name === sevaName);
    return seva ? parseFloat(seva.seva_price) : 0;
  };

  const getSevaID = (sevaName) => {
    const seva = sevaOptions.find((option) => option.seva_name === sevaName);
    return seva ? seva.id : "";
  };

  const handleDropdownChange = (value, index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        seva_name: value,
        seva_price: getSevaPrice(value),
        seva_units: updatedRows[index].seva_units || 1,
        seva_total: getSevaPrice(value) * (updatedRows[index].seva_units || 1),
        temple_seva_id: getSevaID(value),
      };
      return updatedRows;
    });
  };

  const handleAddDevotee = () => {
    setLastId(lastId + 1);
    setDevotees((prevDevotees) => [
      ...prevDevotees,
      {
        devotee_id: lastId,
        devotee_name: "",
        devotee_gotra: "Select Gothra",
        devotee_rashi: "Select Rashi",
        devotee_nakshatra: "Select Nakshathra",
        devotee_relationship: "Select Relationship",
      },
    ]);
  };

  const handleDeleteDevotee = (index) => {
    setDevotees((prevDevotees) => {
      const updatedDevotees = [...prevDevotees];
      updatedDevotees.splice(index, 1);
      return updatedDevotees;
    });
  };

  const handleDevoteeChange = (index, field, value) => {
    setDevotees((prevDevotees) => {
      const updatedDevotees = [...prevDevotees];
      updatedDevotees[index][field] = value;
      return updatedDevotees;
    });
  };

  useEffect(() => {
    const totalAmount = rows?.reduce((sum, row) => {
      const sevaTotal = parseFloat(row.seva_total) || 0;
      return sum + sevaTotal;
    }, 0);
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      total_amount: totalAmount,
    }));
  }, [rows]);

  const handlePaymentDetailsChange = (field, value) => {
    setPaymentDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };
  const [selectedSevaStatus, setSelectedSevaStatus] = useState(
    paymentDetails.seva_status || "on-processing"
  );

  const handleSevaStatusChange = (e) => {
    const newValue = e.target.value;
    setSelectedSevaStatus(newValue);
    handlePaymentDetailsChange("seva_status", newValue);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleImageUpload = async(event) => {
    const fileObj = event.target.files[0];
    const bucketName = "mydharmik-sevalist";
    const uploadedImageUrl = await upload(fileObj, bucketName);
    if (uploadedImageUrl) {
      setFormData((prevData) => ({
        ...prevData,
        user_seva_completed_image: uploadedImageUrl,
      }));
    }
  };

  return (
    <div>
      <div className="overflow-y-auto overflow-x-hidden mt-5 mb-5">
        {successMessage && (
          <div className="flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
            <div className="w-10">
              <Check />
            </div>
            {successMessage}
          </div>
        )}
        <div className="border border-[#000000] dark:border-[#3b3b3b]">
          <div className="overflow-y-auto overflow-x-hidden m-5">
            {rows.map((row, index) => (
              <div
                key={index}
                className="flex items-center mb-5 w-full dark:bg-[#2d2d2d] "
              >
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      className="w-full justify-start text-left font-normal"
                    >
                      {row.seva_name || "Select Seva"}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="start"
                    className="min-w-[300px] max-h-[200px] overflow-y-auto bg-[#FFFFFF] dark:bg-[#2d2d2d] text-[#000000] dark:text-[#FFFFFF] border-0 bg-[#feedd5]" 
                  >
                    <DropdownMenuLabel>Select Seva</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {sevaOptions.map((option) => (
                      <DropdownMenuItem
                        key={option.seva_name}
                        onSelect={() =>
                          handleDropdownChange(option.seva_name, index)
                        }
                        className={`${row.seva_name === option.seva_name
                            ? "bg-gray-200 dark:bg-gray-600 "
                            : ""
                          }`}
                      >
                        {option.seva_name}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
                <Minus
                  className="text-black dark:text-white text-[20px] cursor-pointer ml-2"
                  onClick={() => handleDecrement(index)}
                />
                <span style={{ margin: "0 10px" }}>{row.seva_units}</span>
                <Plus
                  className="text-black dark:text-white text-[20px] ml-2 cursor-pointer"
                  onClick={() => handleIncrement(index)}
                />
                <span
                  className="k-icon k-font-icon k-i-delete text-[#673011] dark:text-[#ffffff]  text-lg ml-2 cursor-pointer"
                  onClick={() => handleDelete(index)}
                ></span>
              </div>
            ))}

            <Button
              className="bg-[#673011]  text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              onClick={handleAddSeva}
            >
              {BUTTONS.ADD_SEVA}
            </Button>
          </div>

          <div className="h-[150px] overflow-y-auto overflow-x-hidden m-5">
            <Grid data={rows} className="mt-4 border-b-black dark:border-t-white dark:border-b-white">
              <Column field="seva_name" title="Seva Name" />
              <Column field="seva_price" title="Seva Price" />
              <Column field="seva_units" title="Seva Units" />
              <Column field="seva_total" title="Seva Total" />
            </Grid>
          </div>
        </div>

        <div className="border border-[#000000] dark:border-[#3b3b3b] mt-5">
          <div className="h-36 overflow-y-auto overflow-x-hidden m-5">
            {devotees.map((devotee, index) => (
              <div key={devotee.devotee_id} className="flex items-center mb-5">
                <Input
                  class="dark:placeholder-[#FFFFFF] dark:bg-[#3b3b3b] "
                  placeholder="Name"
                  value={devotee.devotee_name}
                  onChange={(e) =>
                    handleDevoteeChange(index, "devotee_name", e.target.value)
                  }
                />
                <DropDownList
                  className="ml-2 dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={[
                    "Aatreya",
                    "Atreya",
                    "Agastya",
                    "Angirasa",
                    "Atri",
                    "Bharadwaja",
                    "Bhrigu",
                    "Dhananjaya",
                    "Garga",
                    "Gautama",
                    "Harita",
                    "Jamdagni",
                    "Kashyapa",
                    "Kaushika",
                    "Kaundinya",
                    "Kutsa",
                    "Mandavya",
                    "Mudgala",
                    "Maitreya",
                    "Parashara",
                    "Pulastya",
                    "Pulaha",
                    "Rathitar",
                    "Sankrithi",
                    "Shandilya",
                    "Shrivatsa",
                    "Soubharya",
                    "Soral",
                    "Vashistha",
                    "Vishwamitra",
                    "Vishnuvardhana",
                  ]}
                  value={devotee.devotee_gotra}
                  onChange={(e) =>
                    handleDevoteeChange(index, "devotee_gotra", e.target.value)
                  }
                />
                <DropDownList
                  className="ml-2 dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={[
                    "Mesha (Aries)",
                    "Vrishabha (Taurus)",
                    "Mithuna (Gemini)",
                    "Karka (Cancer)",
                    "Simha (Leo)",
                    "Kanya (Virgo)",
                    "Tula (Libra)",
                    "Vrishchika (Scorpio)",
                    "Dhanu (Sagittarius)",
                    "Makara (Capricorn)",
                    "Kumbha (Aquarius)",
                    "Meena (Pisces)",
                  ]}
                  value={devotee.devotee_rashi}
                  onChange={(e) =>
                    handleDevoteeChange(index, "devotee_rashi", e.target.value)
                  }
                />
                <DropDownList
                  className="ml-2 dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={[
                    "Ashwini",
                    "Bharani",
                    "Krittika",
                    "Rohini",
                    "Mrigashira",
                    "Ardra",
                    "Punarvasu",
                    "Pushya",
                    "Ashlesha",
                    "Magha",
                    "Purva Phalguni",
                    "Uttara Phalguni",
                    "Hasta",
                    "Chitra",
                    "Swati",
                    "Vishakha",
                    "Anuradha",
                    "Jyeshtha",
                    "Mula",
                    "Purva Ashadha",
                    "Uttara Ashadha",
                    "Shravana",
                    "Dhanishta",
                    "Shatabhisha",
                    "Purva Bhadrapada",
                    "Uttara Bhadrapada",
                    "Revati",
                  ]}
                  value={devotee.devotee_nakshatra}
                  onChange={(e) =>
                    handleDevoteeChange(
                      index,
                      "devotee_nakshatra",
                      e.target.value
                    )
                  }
                />
                <DropDownList
                  data={[
                    "Self",
                    "Father",
                    "Mother",
                    "Son",
                    "Daughter",
                    "Husband",
                    "Wife",
                    "Other",
                  ]}
                  className="ml-2 dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  placeholder="Relationship"
                  value={devotee.devotee_relationship}
                  onChange={(e) =>
                    handleDevoteeChange(
                      index,
                      "devotee_relationship",
                      e.target.value
                    )
                  }
                />
                <span
                  className="k-icon k-font-icon k-i-delete text-[#673011] dark:text-[#ffffff]  text-lg ml-2 cursor-pointer"
                  onClick={() => handleDeleteDevotee(index)}
                ></span>
              </div>
            ))}
            <Button
              className="bg-[#673011] text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              onClick={handleAddDevotee}
            >
              {BUTTONS.ADD_DEVOTEE_DETAILS}
            </Button>
          </div>
        </div>

        <div className="border border-[#000000] dark:border-[#3b3b3b] mt-5">
          <div className=" mt-5 ">
            <div className="flex justify-evenly mb-5 ">
              <div className=" w-[18%]">
                <span>{SUB_TITLES.TOTAL_AMOUNT}</span>
                <Input value={paymentDetails.total_amount} disabled />
              </div>
              <div className=" w-[18%] ">
                <span>{SUB_TITLES.SEVA_DATE}</span>
                <DatePicker
                  className="dark:bg-[#3b3b3b]"
                  value={paymentDetails.seva_date}
                  onChange={(e) =>
                    handlePaymentDetailsChange("seva_date", e.target.value)
                  }
                  format={"dd-MM-yyyy"}
                />
              </div>
              <div className=" w-[18%] ">
                <span>{SUB_TITLES.SEVA_TIME}</span>
                <TimePicker
                  value={paymentDetails.seva_time}
                  onChange={(e) =>
                    handlePaymentDetailsChange("seva_time", e.target.value)
                  }
                />
              </div>
              <div className=" w-[18%]">
                <span>{SUB_TITLES.PAYMENT_TYPE}</span>
                <DropDownList
                  className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={["cash", "upi"]}
                  value={paymentDetails.payment_type}
                  onChange={(e) =>
                    handlePaymentDetailsChange("payment_type", e.target.value)
                  }
                />
              </div>
              <div className="w-[18%]">
                <span>{SUB_TITLES.PAYMENT_STATUS}</span>
                <DropDownList
                  className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={["success", "pending", "failure"]}
                  value={paymentDetails.payment_status}
                  onChange={(e) =>
                    handlePaymentDetailsChange("payment_status", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex justify-evenly mb-5 items-center">
              <div className="w-[18%]">
                <span>{SUB_TITLES.PAY_AT_TEMPLE}</span>
                <DropDownList
                  className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={["paid", "pending"]}
                  value={paymentDetails.pay_at_temple}
                  onChange={(e) =>
                    handlePaymentDetailsChange("pay_at_temple", e.target.value)
                  }
                />
              </div>
              <div className=" w-[18%]">
                <span>{SUB_TITLES.SEVA_STATUS}</span>
                <DropDownList
                  className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5]"
                  data={["on-processing", "accepted", "completed"]}
                  value={selectedSevaStatus}
                  onChange={handleSevaStatusChange}
                />
              </div>
              {selectedSevaStatus === "completed" && (
                <div className="w-[18%]">
                  <span>Seva Image</span>
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    className="block w-full text-sm text-gray-900 border border-black dark:border-white rounded-[6px] cursor-pointer dark:bg-[#3b3b3b] dark:text-white bg-[#feedd5]"
                  />
                </div>
              )}

              <div className="w-[38%]">
                <span>{SUB_TITLES.SEVA_REASON}</span>
                <TextArea
                  placeholder="Seva Reason"
                  value={paymentDetails.seva_reason}
                  onChange={(e) =>
                    handlePaymentDetailsChange("seva_reason", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          className=" mt-10 bg-[#673011] text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
          onClick={handleClose}
        >
          {BUTTONS.CLOSE}
        </Button>
        <Button
          style={{
            backgroundColor: isSubmitted
              ? "[#673011]"
              : document.documentElement.classList.contains("dark")
                ? "[#FFFFFF]"
                : "[#000000]",
          }}
          primary
          //className="bg-gray-900 text-[#ffffff] mb-10 mr-5 rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
          className="bg-[#673011] text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"

          onClick={handlePrint}
          disabled={isSubmitted}
        >
          {BUTTONS.PRINT}
        </Button>
        <Button
          className="bg-[#673011] text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
          onClick={handleSubmit}
        >
          {BUTTONS.SUBMIT}
        </Button>
      </div>
    </div>
  );
};

export default AddSevaModal;
