import React, { useState, useEffect } from "react";
import { MapPin, Youtube, Link, Phone, Check, MailIcon } from "lucide-react";
import { Button } from "../../ui/button";
import axiosInstance from "../../common/constant/axiosInstance";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";

const AddTempleDetails = ({ handleSave, existingDetails, templeId }) => {
  const [templeName, setTempleName] = useState(
    existingDetails?.temple_name || ""
  );
  const [locationURL, setLocationURL] = useState(
    existingDetails?.temple_map_url || ""
  );
  const [youtubeURL, setYoutubeURL] = useState(
    existingDetails?.event_live_stream_url || ""
  );
  const [websiteURL, setWebsiteURL] = useState(
    existingDetails?.temple_url || ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    existingDetails?.temple_phone || ""
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateMobileNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(number);
  };

  const handleFormSave = async () => {
    if (!validateMobileNumber(mobileNumber)) {
      setErrorMessage(
        "Mobile number must be exactly 10 digits and contain only numbers."
      );
      return;
    }

    const templeData = {
      temple_name: templeName,
      temple_map_url: locationURL,
      event_live_stream_url: youtubeURL,
      temple_url: websiteURL,
      temple_phone: mobileNumber,
    };

    try {
      const response = await axiosInstance.put(
        `/api/v1/about-temple/${templeId}`,
        templeData
      );
      if (response.data.status) {
        handleSave(response.data.data);
        setSuccessMessage("Data saved successfully!");
        setTimeout(() => setSuccessMessage(""), 2000);
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Failed to update temple details: " + response.data.message
        );
      }
    } catch (error) {
      setErrorMessage("Error updating temple details: " + error.message);
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      if (validateMobileNumber(value)) {
        setErrorMessage("");
      } else {
        setErrorMessage("Mobile number must be exactly 10 digits.");
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (existingDetails) {
      setTempleName(existingDetails.temple_name);
      setLocationURL(existingDetails.temple_map_url);
      setYoutubeURL(existingDetails.event_live_stream_url);
      setWebsiteURL(existingDetails.temple_url);
      setMobileNumber(existingDetails.temple_phone);
    }
  }, [existingDetails]);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-2/4 mt-5">
        <div className="flex flex-col space-y-2">
          <Label className="font-bold text-base text-left  mt-2">
            Temple Name
          </Label>
          <div className="relative flex items-center">
            <span className="absolute left-3">
              <MailIcon className="h-5 w-5" />
            </span>
            <Input
              type="text"
              className="pl-10 rounded-[5px] bg-[#ffffff73]"
              value={templeName}
              onChange={(e) => setTempleName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <Label className="font-bold text-base text-left  mt-2">
            Live location URL
          </Label>
          <div className="relative flex items-center">
            <a
              href={locationURL}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute left-3"
            >
              <MapPin className="h-5 w-5" />
            </a>
            <Input
              type="text"
              className="pl-10 rounded-[5px] bg-[#ffffff73]"
              value={locationURL}
              onChange={(e) => setLocationURL(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <Label className="font-bold text-base text-left  mt-2">
            Live YouTube URL
          </Label>
          <div className="relative flex items-center">
            <a
              href={youtubeURL}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute left-3"
            >
              <Youtube className="h-5 w-5" />
            </a>
            <Input
              type="text"
              className="pl-10 rounded-[5px] bg-[#ffffff73]"
              value={youtubeURL}
              onChange={(e) => setYoutubeURL(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <Label className="font-bold text-base text-left  mt-2">
            Website URL
          </Label>
          <div className="relative flex items-center">
            <a
              href={websiteURL}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute left-3"
            >
              <Link className="h-5 w-5" />
            </a>
            <Input
              type="text"
              className="pl-10 rounded-[5px] bg-[#ffffff73]"
              value={websiteURL}
              onChange={(e) => setWebsiteURL(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <Label className="font-bold text-base text-left  mt-2">
            Mobile Number
          </Label>
          <div className="relative flex items-center">
            <span className="absolute left-3">
              <Phone className="h-5 w-5" />
            </span>
            <Input
              type="text"
              className="pl-10 rounded-[5px] bg-[#ffffff73]"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </div>
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
        </div>

        <div className="flex justify-center lg:w-[100%] mt-5 xs:w-full">
          <Button
            onClick={handleFormSave}
            className="bg-[#673011] text-[#FDDEB2] rounded-[0.375rem] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
          >
            SAVE
          </Button>
        </div>
      </div>

      {successMessage && (
        <div className=" flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
          <div className="w-10">
            <Check />
          </div>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AddTempleDetails;
