import { Button } from "../../ui/button";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import React, { useEffect, useState } from "react";
import upload from "../../common/constant/upload";
import { BUTTONS, SUB_TITLES } from "../../common/constant/const";
import { Check } from "lucide-react";

import "../seva-list/style.css";

const AddEventsModal = ({ eventData, addEvent, editEvent, onClose }) => {
  const [formData, setFormData] = useState(eventData || {});
  const [previewPhoto, setPreviewPhoto] = useState(
    eventData?.event_photo || ""
  );
  const [previewInvitation, setPreviewInvitation] = useState(
    eventData?.event_invitation || ""
  );
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 412);
  const [successMessage, setSuccessMessage] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isEventPhotoChanged, setIsEventPhotoChanged] = useState(false);
  const [isEventInvitationChanged, setIsEventInvitationChanged] =
    useState(false);

  useEffect(() => {
    setFormData(eventData || {});
    setPreviewPhoto(eventData?.event_photo || "");
    setPreviewInvitation(eventData?.event_invitation || "");
  }, [eventData]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 412);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      event_date: event.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      ...formData,
      event_photo: isEventPhotoChanged ? formData.event_photo : undefined,
      event_invitation: isEventInvitationChanged
        ? formData.event_invitation
        : undefined,
    };

    if (formData.id) {
      await editEvent(updatedData);
      setSuccessMessage("Event updated successfully!");
    } else {
      await addEvent(updatedData);
      setSuccessMessage("Event added successfully!");
    }
    setTimeout(() => {
      setSuccessMessage("");
      onClose();
    }, 2000);
  };

  const handleEventPhotoButtonClick = (event) => {
    event.preventDefault();
    document.getElementById("eventPhotoInput").click();
  };

  const handleEventInvitationButtonClick = (event) => {
    event.preventDefault();
    document.getElementById("eventInvitationInput").click();
  };

  const handleEventPhoto = async (event) => {
    setIsImageLoading(true);
    try {
      const bucketName = "mydharmik-event";
      const fileObj = event.target.files[0];
      const uploadedImageUrl = await upload(fileObj, bucketName);
      if (uploadedImageUrl) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          event_photo:
            uploadedImageUrl || "gs://mydharmik-event/default_event.jpeg",
        }));
        setPreviewPhoto(URL.createObjectURL(event.target.files[0]));
        setIsEventPhotoChanged(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsImageLoading(false);
    }
  };

  const handleEventInvitation = async (event) => {
    setIsImageLoading(true);
    try {
      const bucketName = "mydharmik-event";
      const fileObj = event.target.files[0];
      const uploadedImageUrl = await upload(fileObj, bucketName);
      if (uploadedImageUrl) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          event_invitation:
            uploadedImageUrl || "gs://mydharmik-event/default_event.jpeg",
        }));
        setPreviewInvitation(URL.createObjectURL(event.target.files[0]));
        setIsEventInvitationChanged(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <Dialog
      title={
        <span className="font-bold">
          {formData.id ? "Update Event" : "Add Event"}
        </span>
      }
      onClose={onClose}
      className={isSmallScreen ? "custom-dialog-xs" : ""}
    >
      {successMessage && (
        <div className="flex flex-row fixed top-20 right-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF] p-3 rounded shadow-lg z-50 justify-between">
          <div className="w-10">
            <Check />
          </div>
          {successMessage}
        </div>
      )}
      <style>
        {isSmallScreen &&
          `
          .custom-dialog-xs .k-dialog {
            width: 100% !important;
          }
          .custom-dialog-xs .custom-button-xs {
            width: 300px !important;
          }
        `}
      </style>
      <form onSubmit={handleSubmit} className="space-y-5 p-5 md:p-2">
        <div>
          <Label className="block mb-2">{SUB_TITLES.EVENT_NAME}</Label>
          <Input
            name="event_name"
            value={formData.event_name || ""}
            onChange={handleChange}
            required
            className="w-full bg-[#feedd5] dark:bg-[#3b3b3b] rounded-[7px]"
          />
        </div>

        <div>
          <Label className="block mb-2">{SUB_TITLES.EVENT_DATE}</Label>
          <DatePicker
            value={formData.event_date ? new Date(formData.event_date) : null}
            onChange={handleDateChange}
            required
            className="w-full bg-[#feedd5] dark:bg-[#3b3b3b]"
            format={"dd-MM-yyyy"}
          />
        </div>

        <div>
          <Label className="block mb-2">{SUB_TITLES.EVENT_TIME}</Label>
          <TimePicker
            value={
              new Date(
                1970,
                0,
                1,
                formData.event_time?.hours || 0,
                formData.event_time?.minutes || 0
              )
            }
            onChange={(event) => {
              setFormData({
                ...formData,
                event_time: {
                  hours: event.value.getHours(),
                  minutes: event.value.getMinutes(),
                  seconds: 0,
                },
              });
            }}
            className="bg-[#feedd5] dark:bg-[#3b3b3b]"
            format={"HH:mm"}
          />
        </div>

        <div>
          <Label className="block mb-2">{SUB_TITLES.EVENT_COMMENT}</Label>
          <TextArea
            name="event_description"
            multiline
            rows={4}
            value={formData.event_description || ""}
            onChange={handleChange}
            className="w-full bg-[#feedd5] dark:bg-[#3b3b3b]"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col items-start flex-1">
            <Label className="block mb-2">{SUB_TITLES.EVENT_PHOTO}</Label>
            {previewPhoto ? (
              <div className="flex flex-col items-start">
                <img
                  src={previewPhoto}
                  alt="Event"
                  className="w-20 h-20 object-cover mb-2 rounded"
                />
                <Button
                  look="outline"
                  className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000] ${
                    isSmallScreen ? "custom-button-xs" : ""
                  }`}
                  onClick={handleEventPhotoButtonClick}
                >
                  Change Event Photo
                </Button>
              </div>
            ) : (
              <Button
                look="outline"
                className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000] ${
                  isSmallScreen ? "custom-button-xs" : ""
                }`}
                onClick={handleEventPhotoButtonClick}
              >
                Upload Event Photo
              </Button>
            )}
            <input
              id="eventPhotoInput"
              type="file"
              onChange={handleEventPhoto}
              className="hidden"
            />
          </div>

          <div className="flex flex-col items-start flex-1">
            <Label className="block mb-2">{SUB_TITLES.EVENT_INVITATION}</Label>
            {previewInvitation ? (
              <div className="flex flex-col items-start">
                <img
                  src={previewInvitation}
                  alt="Invitation"
                  className="w-20 h-20 object-cover mb-2 rounded"
                />
                <Button
                  className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000] ${
                    isSmallScreen ? "custom-button-xs" : ""
                  }`}
                  onClick={handleEventInvitationButtonClick}
                >
                  Change Invitation
                </Button>
              </div>
            ) : (
              <Button
                look="outline"
                className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-30 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md dark:bg-[#FFFFFF] dark:text-[#000000] ${
                  isSmallScreen ? "custom-button-xs" : ""
                }`}
                onClick={handleEventInvitationButtonClick}
              >
                Upload Invitation
              </Button>
            )}
            <input
              id="eventInvitationInput"
              type="file"
              onChange={handleEventInvitation}
              className="hidden"
            />
          </div>
        </div>

        <div className="mb-5">
          <span className="block mb-2">{SUB_TITLES.EVENT_URL}</span>
          <Input
            name="event_live_stream_url"
            className="bg-[#feedd5] dark:bg-[#3b3b3b] rounded-[7px]"
            value={formData.event_live_stream_url || ""}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-2 mt-5">
          <Button
            onClick={onClose}
            type="button"
            className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:bg-[#FFFFFF] dark:text-[#000000] dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
            {BUTTONS.CLOSE}
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isImageLoading}
            className={`bg-[#673011] text-[#FFFFFF] rounded-[0.375rem] w-48 text-sm transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 dark:bg-[#FFFFFF] dark:text-[#000000] dark:shadow-md ${
              isSmallScreen ? "custom-button-xs" : ""
            }`}
          >
            {formData.id ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddEventsModal;
