import React, { useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./confirm-modal"; 

const Logout = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowConfirmModal(true);
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/"); 
      })
      .catch((error) => {
        console.error("Failed to log out:", error); 
      });
  };

  const handleConfirm = () => {
    handleLogout(); 
    setShowConfirmModal(false);
  };

  const handleCancel = () => {
    setShowConfirmModal(false); 
    navigate(-1); 
  };

  return (
    <div>
      {showConfirmModal && (
        <ConfirmModal
          message="Are you sure you want to log out?" 
          onConfirm={handleConfirm} 
          onCancel={handleCancel} 
        />
      )}
    </div>
  );
};

export default Logout;
