import React, { useState } from "react";
import { Card } from "../../ui/card";
import { Button } from "../../ui/button";
import DailyActiveUsers from "./daily-active-users";
import DailySevaRevenue from "./daily-seva-revenue";
import { BUTTONS } from "../../common/constant/const";

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="w-full">
      <div
        className={`w-full flex flex-col items-center bg-[#FDDEB2] dark:bg-[#000000]  pb-10${
          isMenuOpen ? "blur-md lg:blur-none" : ""
        }`}
      >
        <Button
          className="text-[#FDDEB2] bg-[#673011] m-5 hover:text-[#673011] dark:text-[#ffffff] dark:hover:text-[#000000] dark:hover:bg-[#ffffff] dark:bg-[#000000]"
          style={{ boxShadow: "1px 1px 2px 0px #565656" }}
        >
          {BUTTONS.MONTHLY_ACTIVE_USERS}
        </Button>
        <div className="w-full flex flex-col lg:flex-row items-center justify-evenly space-y-4 lg:space-y-0">
          <Card className="shadow-xl w-full sm:w-4/5 lg:w-2/5 p-4 bg-[#feedd5] dark:bg-[#000000]">
            <DailyActiveUsers />
          </Card>
          <Card className="shadow-xl w-full sm:w-4/5 lg:w-2/5 p-4 bg-[#feedd5] dark:bg-[#000000]">
            <DailySevaRevenue />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
