import React, { useEffect, useState } from "react";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Check, X } from "lucide-react";
import axiosInstance from "../../../common/constant/axiosInstance";
import { LABELS, BUTTONS, SUB_TITLES } from "../../../common/constant/const";
import upload from "../../../common/constant/upload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";

const EditSevaModal = ({ sevaData, onClose, onRefresh, addPrint }) => {
  const userSevaId = sevaData.user_seva_details.user_seva_id;
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [formData, setFormData] = useState({
    receipt_number: "",
    first_name: "",
    total_amount: 0.0,
    payment_type: "",
    payment_status: "",
    seva_status: "On Processing",
    seva_image: null,
    user_seva_completed_image: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (sevaData) {
      setFormData({
        receipt_number: sevaData.user_seva_details.receipt_number,
        first_name: sevaData.user_seva_details.first_name,
        total_amount: sevaData.user_seva_details.total_amount,
        payment_type: sevaData.user_seva_details.payment_type,
        payment_status: sevaData.user_seva_details.payment_status,
        seva_status: sevaData.user_seva_details.seva_status,
        seva_image: null,
        user_seva_completed_image: "",
      });
    }
  }, [sevaData]);

  useEffect(() => {
    if (!sevaData) return;
    if (formData.payment_type !== sevaData.user_seva_details.payment_type) {
      if (formData.payment_type === "cash") {
        setFormData((prevData) => ({
          ...prevData,
          payment_status: "paid",
        }));
      } else if (formData.payment_type === "upi") {
        setFormData((prevData) => ({
          ...prevData,
          payment_status: "success",
        }));
      }
    }
  }, [formData.payment_type, sevaData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        seva_image: file,
      }));
    }
  };

  const handleSave = async () => {
    setIsImageLoading(true);
    let uploadedImageUrl = formData.user_seva_completed_image;
    if (formData.seva_image) {
      const bucketName = "mydharmik-sevalist";
      uploadedImageUrl = await upload(formData.seva_image, bucketName);
    }

    const formattedFormData = {
      total_amount: formData.total_amount
        ? parseFloat(formData.total_amount)
        : 0.0,
      payment_type: formData.payment_type ? formData.payment_type : "",
      payment_status: formData.payment_status ? formData.payment_status : "",
      payment_provider: formData.payment_type === "cash" ? "cash" : "online",
      pay_at_temple: true,
      seva_status: formData.seva_status ? formData.seva_status : "",
      transaction_date_time: new Date().toISOString(),
      user_seva_completed_image: uploadedImageUrl || null,
    };

    console.log("FormData being saved:", formattedFormData);

    try {
      const response = await axiosInstance.put(
        `/api/v1/seva-offering/update/${userSevaId}`,
        formattedFormData
      );
      if (response.data.status) {
        setIsSubmitted(false);
        setSuccessMessage("Data updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        console.log("Success: Data updated successfully");
      } else {
        console.error("Failed to update data", response.data);
      }
    } catch (error) {
      console.error("Error posting data: ", error);
      setSuccessMessage("Failed to update data");
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    } finally {
      setIsImageLoading(false);
    }
  };

  const handlePrint = async () => {
    await addPrint(formData.receipt_number);
    onClose();
    onRefresh();
  };

  const handleClose = async () => {
    onClose();
    onRefresh();
  };
  
  const handlePaymentDetailsChange = (field, value) => {
    setFormData((prevDetails) => ({ ...formData, [field]: value }));
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-0">
      {successMessage && (
        <div
          className={`flex flex-row fixed top-20 right-20 p-3 rounded shadow-lg z-50 justify-between bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-[#FFFFFF]`}
        >
          <div className="w-10 flex items-center justify-center">
            {successMessage.startsWith("Failed") ? (
              <X className="text-white" />
            ) : (
              <Check className="text-white" />
            )}
          </div>
          <span>{successMessage}</span>
        </div>
      )}
      <div className="bg-[#fddeb2] dark:bg-[#2d2d2d] mt-10 mb-20 p-5 w-[470px]">
        <Dialog>
          <div className="w-[470px]"> 
          <h1 className="text-[#000000] dark:text-[#ffffff] font-bold text-xl flex justify-center">
            {LABELS.EDIT_SEVA_OFFERING}
          </h1>
          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.RECEIPT_NUMBER}
            </Label>
            <Input
              value={formData.receipt_number}
              disabled
              className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b] rounded-[6px]"
            />
          </div>
          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.USER_NAME}
            </Label>
            <Input
              value={formData.first_name}
              disabled
              className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b] rounded-[6px]"
            />
          </div>
          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.TOTAL_AMOUNT}
            </Label>
            <Input
              value={formData.total_amount}
              disabled
              className="bg-[#ffffff] text-[#000000] dark:text-[#ffffff] dark:bg-[#3b3b3b] rounded-[6px]"
            />
          </div>

          <div>
            <Label className="w-full mr-2  text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.PAYMENT_TYPE}
            </Label>
            <DropDownList
              className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border  border-[#693F19] dark:border-white bg-[#feedd5] p-[4px]"
              data={["cash", "upi"]}
              value={formData.payment_type}
              onChange={(e) =>
                handlePaymentDetailsChange("payment_type", e.target.value)
              }
            />
          </div>

          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.PAYMENT_STATUS}
            </Label>
            <DropDownList
              className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border border-[#693F19] dark:border-white bg-[#feedd5] p-[4px]"
              data={
                formData.payment_type === "cash"
                  ? ["paid", "pending"]
                  : formData.payment_type === "upi"
                  ? ["success", "pending", "failure"]
                  : []
              }
              value={formData.payment_status}
              onChange={(e) =>
                handlePaymentDetailsChange("payment_status", e.target.value)
              }
            />
          </div>

          <div>
            <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
              {SUB_TITLES.SEVA_STATUS}
            </Label>
            <DropDownList
              className="dark:bg-[#3b3b3b] dark:text-[#FFFFFF] dark:hover:bg-[#2c2c2c] border border-[#693F19] dark:border-white bg-[#feedd5] p-[4px]"
              data={["on-processing", "accepted", "completed"]}
              value={formData.seva_status}
              onChange={(e) =>
                handlePaymentDetailsChange("seva_status", e.target.value)
              }
            />
          </div>

          {formData.seva_status === "completed" && (
            <div>
              <Label className="w-full mr-2 text-base flex justify-between mt-2 mb-2 text-[#000000] dark:text-[#ffffff]">
                {SUB_TITLES.user_seva_completed_image}
              </Label>
              <div className="flex items-center">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="seva_image"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="seva_image"
                  className="bg-[#f5f5f5] text-[#000000] dark:bg-[#3b3b3b] dark:text-[#ffffff] border border-[#000000] dark:border-[#ffffff] p-1 rounded cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-[#4b4b4b] transition-colors"
                >
                  Choose File
                </label>
                <span className="ml-2 text-sm text-[#000000] dark:text-[#ffffff]">
                  {formData.seva_image
                    ? formData.seva_image.name
                    : "No file chosen"}
                </span>
              </div>
            </div>
          )}
          <div className="flex items-center justify-center pt-5 ">
            <Button
              className="bg-[#673011]  text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              primary
              onClick={handleSave}
            >
              {BUTTONS.SAVE}
            </Button>
            <Button
              style={{
                backgroundColor: isSubmitted ? "[#673011]" : "gray-700",
                color: "[#000000]",
              }}
              primary
              className="bg-[#673011] text-[#FDDEB2] mr-5 rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              onClick={handlePrint}
              disabled={isSubmitted}
            >
              {BUTTONS.PRINT}
            </Button>
            <Button
              className="bg-[#673011] text-[#FDDEB2] rounded-[0.375rem] hover:transition-colors dark:hover:bg-[#e0e0e0] w-48 text-sm border border-transparent transition duration-300 ease-in-out shadow-lg uppercase font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 hover:bg-gray-700 hover:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#000000] dark:border-gray-300 dark:hover:border-gray-500 dark:shadow-md"
              primary
              onClick={handleClose}
            >
              {BUTTONS.CLOSE}
            </Button>
          </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default EditSevaModal;
