import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "next-themes";
import RoutesComponent from "./controller/routes/routes-components";

import "./App.css";
import "./index.css";

function App() {
  return (
    <ThemeProvider attribute="class">
      <div className="App">
        <BrowserRouter>
          <RoutesComponent />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
