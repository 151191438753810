import React from 'react';

const EventCard = ({ event, onEdit, onDelete }) => {
  return (
    <div className="card-container bg-[#FFFFFF] dark:bg-gray-800 shadow-md rounded-lg p-4 mb-4">
      <div className="card-header flex justify-between items-center mb-2">
        <h3 className="card-title font-bold text-lg">{event.event_name}</h3>
        <div className="card-actions flex">
          <span
            className="k-icon k-font-icon k-i-edit text-xl mr-2 cursor-pointer"
            onClick={() => onEdit(event)}
          ></span>
          <span
            className="k-icon k-font-icon k-i-delete text-xl cursor-pointer"
            onClick={() => onDelete(event)}
          ></span>
        </div>
      </div>
      <div className="card-body">
        <p><strong>ID:</strong> {event.id}</p>
        <p><strong>Date:</strong> {new Date(event.event_date).toLocaleDateString()}</p>
        {event.event_photo && <img src={event.event_photo} alt="event" className="w-12 h-12 mb-2" />}
        <p><strong>Description:</strong> {event.event_description}</p>
      </div>
    </div>
  );
};

export default EventCard;
