import React, { useEffect, useState } from "react";
import { 
  Chart, 
  ChartArea, 
  ChartTitle, 
  ChartSeries, 
  ChartSeriesItem, 
  ChartCategoryAxis, 
  ChartCategoryAxisItem, 
  ChartCategoryAxisTitle 
} from "@progress/kendo-react-charts";

const firstSeries = [100, 123, 45, 87];
const secondSeries = [120, 67, 124, 154];
const categories = ["Q1", "Q2", "Q3", "Q4"];

const barColors = (point) => {
  if (point.category === "Q1") {
    return "#455078";
  } else if (point.category === "Q2") {
    return "#25414B";
  } else if (point.category === "Q3") {
    return "#455078";
  }
  return "#25414B";
};

const DailyActiveUsers = () => {

  return (
    <Chart>
      <ChartArea background= "#feedd5" />
      <ChartTitle text="Daily Active Users" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={categories}>
          <ChartCategoryAxisTitle text="Months" />
        </ChartCategoryAxisItem>
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem
          type="bar"
          data={firstSeries}
          gap={2}
          color={barColors}
        />
        <ChartSeriesItem
          type="bar"
          data={secondSeries}
          color={barColors}
        />
      </ChartSeries>
    </Chart>
  );
};

export default DailyActiveUsers;
